import { useEffect, useState } from 'react';

/**
 * The visual viewport represents the area of the window that is currently visible to the user. On mobile devices,
 * this excludes areas taken up by UI elements such as the on-screen keyboard.
 */
export interface VisualViewportSize {
  width: number;
  height: number;
}

/**
 * Returns the size of the visual viewport—the portion of the screen that is currently visible to the user.
 *
 * The visual viewport represents the area of the window that is currently visible to the user. On mobile devices,
 * this excludes areas taken up by UI elements such as the on-screen keyboard.
 *
 * @returns An object containing the current width and height of the visible viewport.
 */
export function getVisualViewportSize(): VisualViewportSize {
  return {
    width: window.visualViewport?.width ?? window.innerWidth,
    height: window.visualViewport?.height ?? window.innerHeight,
  };
}

/**
 * Observes changes to the visual viewport size.
 *
 * The visual viewport represents the area of the window that is currently visible to the user. On mobile devices,
 * this excludes areas taken up by UI elements such as the on-screen keyboard.
 *
 * @param callback - A function to be called with the updated visual viewport size whenever a resize event occurs.
 * @returns A cleanup function that, when called, stops the observation of viewport size changes.
 */
export function observeVisualViewportSize(callback: (size: VisualViewportSize) => void): () => void {
  const listener = () => {
    callback(getVisualViewportSize());
  };

  if (window.visualViewport != null) {
    window.visualViewport.addEventListener('resize', listener);
  } else {
    window.addEventListener('resize', listener);
  }

  return () => {
    if (window.visualViewport != null) {
      window.visualViewport.removeEventListener('resize', listener);
    } else {
      window.removeEventListener('resize', listener);
    }
  };
}

/**
 * React hook that returns the current visual viewport size and updates when the size changes.
 *
 * The visual viewport represents the area of the window that is currently visible to the user. On mobile devices,
 * this excludes areas taken up by UI elements such as the on-screen keyboard.
 *
 * @returns An object containing the current width and height of the visible viewport.
 */
export function useVisualViewportSize(): VisualViewportSize {
  const [viewportSize, setViewportSize] = useState(() => getVisualViewportSize());

  useEffect(() => {
    return observeVisualViewportSize(setViewportSize);
  }, []);

  return viewportSize;
}
