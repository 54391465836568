export function stringToRMTextEditorContent(str: string | undefined | null) {
  return {
    type: 'doc',
    content:
      str?.split('\n').map((splitStr) => ({
        type: 'paragraph',
        content:
          splitStr.length > 0
            ? [
                {
                  type: 'text',
                  text: splitStr,
                },
              ]
            : undefined,
      })) ?? [],
  };
}
