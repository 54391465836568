import { ReactionSentiment } from '@remento/types/reaction';
import { createStore, StoreApi, useStore } from 'zustand';

type AssistantState = 'preview' | 'visible' | 'confirm-update';

interface ViewStoryState {
  type: 'view';
  controls: 'visible' | 'hidden';
}

interface EditStoryState {
  type: 'editing';
  action?:
    | 're-record-confirmation'
    | 'delete-confirmation'
    | 'photo-delete-confirmation'
    | 'edit-summary'
    | 'edit-summary-discard-confirmation'
    | null;
  assistant?: AssistantState | null;
  isSaving?: boolean;
}

interface ReactingStoryState {
  type: 'reacting';
  initialSentiment?: ReactionSentiment | null;
}

interface SharingStoryState {
  type: 'sharing';
}

export type StoryState = ViewStoryState | EditStoryState | ReactingStoryState | SharingStoryState;

export type StoryManager = StoreApi<StoryState>;

const INITIAL_STATE: ViewStoryState = {
  type: 'view',
  controls: 'visible',
};

export function createStoryManager(): StoryManager {
  return createStore<StoryState>(() => INITIAL_STATE);
}

export function setStoryState(store: StoryManager, storyState: StoryState): void {
  store.setState(storyState, true);
}

export function getStoryState(store: StoryManager): StoryState {
  return store.getState();
}

export function observeStoryState(
  store: StoryManager,
  listener: (state: StoryState, prevState: StoryState) => void,
): () => void {
  return store.subscribe(listener);
}

export function useStoryState(store: StoryManager): StoryState {
  return useStore(store);
}
