import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Id } from 'react-toastify';
import { createLogger } from '@remento/logger';
import { ACL_EDIT_ROLES } from '@remento/types/acl';
import { AssetProcessingStatus, AssetType } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';
import { PromptType } from '@remento/types/project';
import { ReactionSentiment } from '@remento/types/reaction';
import { StoryDataType } from '@remento/types/story';
import { UserOnboardingActionType } from '@remento/types/user';
import { getStoryDownloadName, getStorySummary, getStoryTitle } from '@remento/utils/entity/story';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMTextEditorStore, setRMTextEditorValue } from '@/components/RMTextEditor/RMTextEditor.state.js';
import { stringToRMTextEditorContent } from '@/components/RMTextEditor/RMTextEditor.utils.js';
import { removeToast, toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { isFormDirty, resetForm } from '@/modules/form/form.js';
import { setInputValue } from '@/modules/form/input';
import { useShowPaywall } from '@/modules/paywall';
import { getQuestionsPath } from '@/modules/routing';
import { StorySummaryForm } from '@/modules/stories/forms/story-summary.form';
import { useStoryVideoAlternative } from '@/modules/stories/hooks/useStoryVideo.ts';
import { useServices } from '@/Services.tsx';
import { hasRole, useCurrentUserAclRoles } from '@/services/api/acl';
import { useAssetQuery } from '@/services/api/asset';
import { useUser } from '@/services/api/auth/auth.service.hook.js';
import { EntityMutation } from '@/services/api/cache';
import { CreatePromptPayload, usePromptFirstQuestionQuery, usePromptQuery } from '@/services/api/project';
import { useCanReact } from '@/services/api/reaction/reaction.service.hook.js';
import { useStoryQuery, useStoryShareLinkQuery } from '@/services/api/story';
import { captureException } from '@/utils/captureException';
import { openFilestackPicker } from '@/utils/filestack';
import { openShareSheet, showShareSheetToast } from '@/utils/share-sheet';
import { secureUuid } from '@/utils/uuid';

import { StoryActions, StoryActionsState } from '../components/StoryActions/StoryActions.js';
import { useStoryActions } from '../hooks/useStoryActions.js';
import { setActiveStoryId, StoriesManager, useActiveStoryId } from '../states/stories.manager.js';
import { setStoryState, StoryManager, useStoryState } from '../states/story.manager.js';

import { RequestAccessDialogContainer } from './RequestAccessDialog.container.js';

const logger = createLogger('story-actions-container');

export interface StoryActionsContainerProps {
  storyId: string;
  storiesManager: StoriesManager;
  storyManager: StoryManager;
  summaryForm: StorySummaryForm;
  titleTextEditor: RMTextEditorStore;
  summaryTextEditor: RMTextEditorStore;
}

export interface StoryActionsContainerRef {
  updatePhoto: () => void;
  removePhoto: () => void;
}

export const StoryActionsContainer = forwardRef<StoryActionsContainerRef, StoryActionsContainerProps>(
  ({ storyId, storiesManager, storyManager, summaryForm, titleTextEditor, summaryTextEditor }, ref) => {
    // Services
    const mobile = useIsMobileViewport();
    const {
      assetService,
      projectCacheService,
      storyService,
      storyCacheService,
      storyViewerAnalyticsService,
      projectService,
      entityCacheManagerService,
      userService,
      userAnalyticsService,
    } = useServices();
    const navigate = useNavigate();

    // Queries
    const user = useUser();
    const activeStoryId = useActiveStoryId(storiesManager);
    const storyState = useStoryState(storyManager);

    const storyQuery = useStoryQuery(storyId);
    const promptQuery = usePromptQuery(storyQuery.data?.promptId ?? null);
    const questionQuery = usePromptFirstQuestionQuery(storyQuery.data?.promptId ?? null);

    const recordingAssetQuery = useAssetQuery(storyQuery.data?.recordingsIds[0]);
    const recordingAlternative = useStoryVideoAlternative(storyId);

    const userStoryRoles = useCurrentUserAclRoles(storyQuery.data?.acl ?? null);
    const canEdit = hasRole(ACL_EDIT_ROLES, userStoryRoles ?? []);
    const canReact = useCanReact(storyQuery.data?.recordingsIds[0]);
    const canDownload = recordingAssetQuery.data?.processingStatus === AssetProcessingStatus.PROCESSED;

    const hasPhoto = (storyQuery.data?.imageAssetIds.length ?? 0) > 0;

    // Actions state
    const [editStoryHintClosed, setEditStoryHintClosed] = useState(false);
    const actionsState = useMemo<StoryActionsState>(() => {
      switch (storyState.type) {
        case 'editing': {
          if (storyState.action === 'edit-summary') {
            return { type: 'editing-text', isSaving: storyState.isSaving ?? false };
          }
          return { type: 'editing' };
        }
        default: {
          return {
            type: 'viewing',
            showEditingHint:
              storyId == activeStoryId &&
              user != null &&
              !(user.onboardingHistory.editStoryHintViewed?.done ?? false) &&
              !editStoryHintClosed,
          };
        }
      }
    }, [storyState, storyId, activeStoryId, user, editStoryHintClosed]);

    // Paywall
    const showPaywall = useShowPaywall(storyQuery.data?.projectId);

    // Request access
    const [requestAccessDialogOpen, setRequestAccessDialogOpen] = useState(false);

    // Share links (for mobile)
    const storyShareLinkQuery = useStoryShareLinkQuery(storyId);
    const shareLink = storyShareLinkQuery.data ?? '';

    const handleShare = useCallback(async () => {
      if (mobile && storiesManager.type !== 'story') {
        const shareResult = await openShareSheet({ url: shareLink });
        showShareSheetToast(shareResult);
        storyViewerAnalyticsService.onStoryShared(
          storiesManager.type,
          shareResult == 'copied-to-clipboard' ? 'clipboard' : 'share-sheet',
        );
        return;
      }

      setStoryState(storyManager, { type: 'sharing' });
    }, [mobile, shareLink, storiesManager.type, storyManager, storyViewerAnalyticsService]);

    // Edit hint tooltip callbacks
    const handleEditHintTooltipClose = useCallback(async () => {
      if (user == null) {
        return;
      }

      setEditStoryHintClosed(true);

      try {
        await entityCacheManagerService.mutate(
          userService.createSetUserOnboardingHistoryMutation(user, UserOnboardingActionType.EDIT_STORY_HINT_VIEWED),
        );
        userAnalyticsService.onOnboardingProgress(UserOnboardingActionType.PURCHASER);
        await userService.refreshUser();
      } catch (error) {
        captureException(error, true);
      }
    }, [entityCacheManagerService, user, userAnalyticsService, userService]);

    const handleEditHintTooltipLearnMore = useCallback(() => {
      window.open(
        'https://help.remento.co/en/articles/8365905-can-i-change-how-remento-writes-my-story',
        'edit-story-faq',
      );
    }, []);

    // Enter and exit edit mode callbacks
    const handleEnterEditMode = useCallback(() => {
      if (showPaywall()) {
        return;
      }

      if (!canEdit) {
        setRequestAccessDialogOpen(true);
        return;
      }

      handleEditHintTooltipClose();
      setStoryState(storyManager, { type: 'editing' });
      storyViewerAnalyticsService.onStoryEditModeArrived();
    }, [canEdit, handleEditHintTooltipClose, showPaywall, storyManager, storyViewerAnalyticsService]);

    const handleExitEditMode = useCallback(() => {
      setStoryState(storyManager, { type: 'view', controls: 'visible' });
    }, [storyManager]);

    // Download story callback
    const handleDownloadStory = useCallback(async () => {
      if (recordingAssetQuery.data == null) {
        logger.warn('RECORDING_ASSET_NOT_LOADED');
        return;
      }
      if (recordingAlternative == null) {
        logger.warn('RECORDING_ALTERNATIVE_NOT_LOADED');
        return;
      }

      let toastId: Id = '';
      let downloadCancelled = false;
      const toasterTimeout = setTimeout(() => {
        toastId = toast('Exporting in progress', 'root-toast', 'default', {
          isLoading: true,
          onLoadingCancel: () => {
            if (toastId) {
              removeToast(toastId);
            }
            downloadCancelled = true;
          },
        });
      }, 2000);

      const ext = recordingAssetQuery.data.type === AssetType.VIDEO_RECORDING ? '.mp4' : '.mp3';
      const filename = getStoryDownloadName(storyQuery.data) + ext;
      const downloadUrl = await assetService.getAlternativeDownloadVideoUrl(recordingAlternative.id, filename);

      clearTimeout(toasterTimeout);

      if (downloadCancelled) return;

      if (toastId) removeToast(toastId);

      window.location.assign(downloadUrl);
      storyViewerAnalyticsService.onStoryDownloaded('original');
    }, [assetService, recordingAlternative, recordingAssetQuery.data, storyQuery.data, storyViewerAnalyticsService]);

    // Delete story callbacks
    const handleAskRemove = useCallback(async () => {
      setStoryState(storyManager, { type: 'editing', action: 'delete-confirmation' });
      storyViewerAnalyticsService.onStoriesActionPerformed('remove-story');
    }, [storyManager, storyViewerAnalyticsService]);

    const handleCancelRemove = useCallback(() => {
      setStoryState(storyManager, { type: 'editing' });
    }, [storyManager]);

    const handleRemoveStory = useCallback(async () => {
      if (storyQuery.data == null) {
        logger.warn('STORY_NOT_LOADED');
        return;
      }

      const deleteMutation = storyService.createDeleteStoryMutation(storyQuery.data);
      await storyCacheService.deleteStory(storyQuery.data.id, deleteMutation);

      setActiveStoryId(storiesManager, null);
      setStoryState(storyManager, { type: 'view', controls: 'visible' });
    }, [storyQuery.data, storyService, storyCacheService, storiesManager, storyManager]);

    // Reaction callbacks
    const handleEditReaction = useCallback(
      (initialSentiment?: ReactionSentiment) => {
        setStoryState(storyManager, { type: 'reacting', initialSentiment });
      },
      [storyManager],
    );

    // Re record callbacks
    const handleAskReRecordStory = useCallback(async () => {
      setStoryState(storyManager, { type: 'editing', action: 're-record-confirmation' });
      storyViewerAnalyticsService.onStoriesActionPerformed('re-record');
    }, [storyManager, storyViewerAnalyticsService]);

    const handleCancelReRecord = useCallback(() => {
      setStoryState(storyManager, { type: 'editing' });
    }, [storyManager]);

    const handleReRecordStory = useCallback(async () => {
      if (storyQuery.data == null) {
        logger.warn('STORY_NOT_LOADED');
        return;
      }
      if (promptQuery.data == null) {
        logger.warn('PROMPT_NOT_LOADED');
        return;
      }
      if (questionQuery.data == null) {
        logger.warn('QUESTION_NOT_LOADED');
        return;
      }

      const newPromptPayload: CreatePromptPayload =
        promptQuery.data.type === PromptType.PHOTO
          ? {
              id: secureUuid(),
              type: PromptType.PHOTO,
              status: null,
              imagesIds: promptQuery.data.imagesIds,
              question: questionQuery.data.text ?? '',
            }
          : {
              id: secureUuid(),
              type: PromptType.TEXT,
              status: null,
              question: questionQuery.data.text ?? '',
              template: promptQuery.data.template,
            };

      const [newPrompt] = await projectCacheService.createPrompts(promptQuery.data.projectId, [newPromptPayload]);

      const cachedPrompt = await projectCacheService.getPrompt(newPrompt.id);
      if (!cachedPrompt) {
        throw new Error('Missing cached prompt with ID ' + newPrompt.id);
      }

      const mutations = projectService.createSetSentMutation(cachedPrompt);
      await entityCacheManagerService.mutate(mutations);

      navigate(getQuestionsPath(promptQuery.data.projectId));
    }, [
      storyQuery.data,
      promptQuery.data,
      questionQuery.data,
      projectCacheService,
      projectService,
      entityCacheManagerService,
      navigate,
    ]);

    // Change photo callbacks
    const handleUpdatePhoto = useCallback(async () => {
      if (storyQuery.data == null) {
        logger.warn('STORY_NOT_LOADED');
        return;
      }

      try {
        await openFilestackPicker({
          accept: ['image/png', 'image/jpeg', 'image/webp'],
          maxSize: 1024 * 1024 * 20,
          onUploadDone: async ({ filesUploaded }) => {
            if (!filesUploaded[0]) {
              return;
            }

            try {
              const newAsset = await assetService.createFilestackAsset({
                entity: { id: storyQuery.data.id, type: EntityType.STORY },
                type: AssetType.IMAGE,
                handle: filesUploaded[0].handle,
              });

              const mutations: EntityMutation[] = [];
              if (storyQuery.data.imageAssetIds.length > 0) {
                mutations.push(
                  ...storyService.createDeleteStoryPhotoMutation(storyQuery.data, storyQuery.data.imageAssetIds[0]),
                );
              }

              mutations.push(...storyService.createAddStoryPhotoMutation(storyQuery.data, newAsset.id));

              await entityCacheManagerService.mutate(mutations);

              if (storyQuery.data.imageAssetIds.length > 0) {
                toast('Photo updated!');
                storyViewerAnalyticsService.onStoryPhotoUpdated();
              } else {
                toast('Photo added!');
                storyViewerAnalyticsService.onStoryPhotoAdded();
              }
            } catch (error) {
              captureException(error, true);
              toast('Failed to update story photo', 'root-toast', 'error');
            }
          },
        });
      } catch (error) {
        captureException(error, true);
      }
    }, [assetService, entityCacheManagerService, storyQuery.data, storyService, storyViewerAnalyticsService]);

    // Remove photo callbacks
    const handleAskRemovePhoto = useCallback(async () => {
      setStoryState(storyManager, { type: 'editing', action: 'photo-delete-confirmation' });
    }, [storyManager]);

    const handleCancelRemovePhoto = useCallback(() => {
      setStoryState(storyManager, { type: 'editing' });
    }, [storyManager]);

    const handleRemovePhoto = useCallback(async () => {
      if (storyQuery.data == null) {
        logger.warn('STORY_NOT_LOADED');
        return;
      }

      try {
        await entityCacheManagerService.mutate([
          ...storyService.createDeleteStoryPhotoMutation(storyQuery.data, storyQuery.data.imageAssetIds[0]),
        ]);
        setStoryState(storyManager, { type: 'editing' });
        toast('Photo removed!');
        storyViewerAnalyticsService.onStoryPhotoRemoved();
      } catch (error) {
        captureException(error, true);
        toast('Failed to update profile', 'root-toast', 'error');
      }
    }, [entityCacheManagerService, storyManager, storyQuery.data, storyService, storyViewerAnalyticsService]);

    // Edit summary callbacks
    const { updateStory } = useStoryActions();

    const handleOpenRegenerate = useCallback(async () => {
      setStoryState(storyManager, { ...storyState, type: 'editing', assistant: 'visible' });
      storyViewerAnalyticsService.onStoryRegenerateOpened();
    }, [storyManager, storyState, storyViewerAnalyticsService]);

    const handleSaveSummary = useCallback(async () => {
      if (storyId == null) {
        return;
      }

      try {
        setStoryState(storyManager, { ...storyState, type: 'editing', isSaving: true });
        setInputValue(summaryForm, 'type', StoryDataType.HUMAN);
        await updateStory(storyId, summaryForm, titleTextEditor, summaryTextEditor);
        setStoryState(storyManager, { ...storyState, type: 'editing', action: null, isSaving: false });
        toast('Story updated!');
      } catch (error) {
        captureException(error, true);
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        setStoryState(storyManager, { ...storyState, type: 'editing', isSaving: false });
      }
    }, [storyId, storyManager, storyState, summaryForm, summaryTextEditor, titleTextEditor, updateStory]);

    const handleDiscardSummary = useCallback(() => {
      if (isFormDirty(summaryForm)) {
        setStoryState(storyManager, { type: 'editing', action: 'edit-summary-discard-confirmation' });
        return;
      }
      setStoryState(storyManager, { type: 'editing' });
    }, [storyManager, summaryForm]);

    const handleConfirmDiscardSummary = useCallback(() => {
      setRMTextEditorValue(titleTextEditor, stringToRMTextEditorContent(getStoryTitle(storyQuery.data)));
      setRMTextEditorValue(summaryTextEditor, stringToRMTextEditorContent(getStorySummary(storyQuery.data)));
      resetForm(summaryForm);
      setStoryState(storyManager, { type: 'editing' });
    }, [storyManager, storyQuery.data, summaryForm, summaryTextEditor, titleTextEditor]);

    const handleCancelDiscardSummary = useCallback(() => {
      setStoryState(storyManager, { type: 'editing', action: 'edit-summary' });
    }, [storyManager]);

    useImperativeHandle(ref, () => ({
      removePhoto: handleAskRemovePhoto,
      updatePhoto: handleUpdatePhoto,
    }));

    return (
      <>
        <StoryActions
          state={actionsState}
          canDownload={canDownload}
          canReact={canReact ?? false}
          hasPhoto={hasPhoto}
          onReact={handleEditReaction}
          onShare={handleShare}
          onEnterEditMode={handleEnterEditMode}
          onExitEditMode={handleExitEditMode}
          onReRecord={handleAskReRecordStory}
          onDownload={handleDownloadStory}
          onRemove={handleAskRemove}
          onUpdatePhoto={handleUpdatePhoto}
          onRemovePhoto={handleAskRemovePhoto}
          onOpenRegenerate={handleOpenRegenerate}
          onSaveSummary={handleSaveSummary}
          onDiscardSummary={handleDiscardSummary}
          onEditHintTooltipClose={handleEditHintTooltipClose}
          onEditHintTooltipLearnMore={handleEditHintTooltipLearnMore}
        />

        <RMConfirmationModal
          open={storyState.type === 'editing' && storyState.action === 'delete-confirmation'}
          title="Remove story?"
          message="This action cannot be undone."
          type="danger"
          confirmLabel="Remove"
          onConfirm={handleRemoveStory}
          onCancel={handleCancelRemove}
          onClose={handleCancelRemove}
        />

        <RMConfirmationModal
          open={storyState.type === 'editing' && storyState.action === 're-record-confirmation'}
          title="Re-record story?"
          message="This action will create and send an identical prompt right now. You’ll be able to delete either at any time."
          confirmLabel="Re-send prompt"
          onConfirm={handleReRecordStory}
          onCancel={handleCancelReRecord}
          onClose={handleCancelReRecord}
        />

        <RMConfirmationModal
          open={storyState.type === 'editing' && storyState.action === 'photo-delete-confirmation'}
          title="Remove photo?"
          message="Are you sure you want to delete this photo? This action cannot be undone."
          type="danger"
          confirmLabel="Yes, remove"
          onConfirm={handleRemovePhoto}
          onCancel={handleCancelRemovePhoto}
          onClose={handleCancelRemovePhoto}
        />

        <RMConfirmationModal
          open={storyState.type === 'editing' && storyState.action === 'edit-summary-discard-confirmation'}
          type="primary"
          title="Save changes?"
          message="You have unsaved changes on this page. Would you like to save them?"
          confirmLabel="Save changes"
          cancelLabel="Discard"
          onCancel={handleConfirmDiscardSummary}
          onConfirm={handleSaveSummary}
          onClose={handleCancelDiscardSummary}
        />

        {requestAccessDialogOpen && storyId != null && (
          <RequestAccessDialogContainer storyId={storyId} onClose={() => setRequestAccessDialogOpen(false)} />
        )}
      </>
    );
  },
);
