import { useRef } from 'react';
import { faPenToSquare, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight, faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import rementoBookSrc from '@/assets/remento-book.webp';
import { RMButton } from '@/components/RMButton/RMButton.js';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { NoNewLine } from '@/components/RMTextEditor/extensions/no-new-line';
import { RMTextEditorRef } from '@/components/RMTextEditor/RMTextEditor.js';
import { RMTextEditorStore } from '@/components/RMTextEditor/RMTextEditor.state';
import { RMTextEditorInputController } from '@/components/RMTextEditor/RMTextEditorInputController';
import { useIsMobileOrTabletViewport } from '@/hooks/useIsMobileViewport';
import { StoryIssueType } from '@/services/api/story';

import { StorySummaryForm } from '../../forms/story-summary.form.js';

import {
  Content,
  LearnMoreButton,
  LearnMoreButtonIcon,
  LearnMoreButtonImage,
  LearnMoreButtonText,
  RegenerateButtonWrapper,
  ScrollArea,
  StoryIssues,
  StoryIssuesWrapper,
  StyledStorySummary,
  summaryInputClass,
  TextEditorButton,
  TextEditorWrapper,
  titleInputClass,
  ViewStoryIssuesButton,
} from './StorySummary.styles.js';

export interface StorySummaryProps {
  form: StorySummaryForm;
  titleTextEditor: RMTextEditorStore;
  summaryTextEditor: RMTextEditorStore;
  state: 'editing' | 'viewing' | 'regenerating';
  saving: boolean;
  showLearnMore: boolean;
  issues: StoryIssueType[] | null;
  onViewIssues: () => void;
  onOpenRegenerate: () => void;
  onStartEditing: () => void;
  onLearnMore: () => void;
}

export function StorySummary({
  form,
  titleTextEditor,
  summaryTextEditor,
  state,
  saving,
  showLearnMore,
  issues,
  onViewIssues,
  onOpenRegenerate,
  onStartEditing,
  onLearnMore,
}: StorySummaryProps) {
  const isMobileOrTablet = useIsMobileOrTabletViewport();

  const titleTextEditorRef = useRef<RMTextEditorRef>(null);
  const summaryTextEditorRef = useRef<RMTextEditorRef>(null);

  return (
    <StyledStorySummary>
      <Content>
        <ScrollArea>
          {(state === 'editing' || state === 'regenerating') && (
            <RegenerateButtonWrapper>
              <RMButton background="none" leftIcon={faSparkles} disabled={saving} onClick={onOpenRegenerate}>
                AI writing tool
              </RMButton>
            </RegenerateButtonWrapper>
          )}

          <TextEditorWrapper>
            {state === 'editing' && (
              <TextEditorButton
                icon={faPenToSquare}
                onClick={() => {
                  titleTextEditorRef.current?.focus('start');
                }}
              />
            )}
            <RMTextEditorInputController
              ref={titleTextEditorRef}
              form={form}
              path="title"
              editable={state === 'editing' && saving === false}
              className={titleInputClass}
              store={titleTextEditor}
              extensions={[NoNewLine]}
              onFocus={onStartEditing}
            />
          </TextEditorWrapper>
          <RMSpacer spacing={isMobileOrTablet ? 'xs' : 'md'} direction="column" />
          <TextEditorWrapper>
            {state === 'editing' && (
              <TextEditorButton
                icon={faPenToSquare}
                onClick={() => {
                  summaryTextEditorRef.current?.focus('start');
                }}
              />
            )}
            <RMTextEditorInputController
              ref={summaryTextEditorRef}
              form={form}
              path="summary"
              editable={state === 'editing' && saving === false}
              className={summaryInputClass}
              store={summaryTextEditor}
              onFocus={onStartEditing}
            />
          </TextEditorWrapper>
          {isMobileOrTablet && showLearnMore && (
            <LearnMoreButton>
              <LearnMoreButtonImage src={rementoBookSrc} onClick={onLearnMore} />
              <LearnMoreButtonText>
                Capture your own family’s stories
                <LearnMoreButtonIcon icon={faChevronRight} />
              </LearnMoreButtonText>
            </LearnMoreButton>
          )}
          {issues != null && issues.length > 0 && <RMSpacer direction="column" spacing="3xl" />}

          {isMobileOrTablet && <RMSpacer direction="column" spacing={'md'} />}
        </ScrollArea>

        {issues != null && issues.length > 0 && isMobileOrTablet == false && (
          <StoryIssuesWrapper>
            <StoryIssues>
              <FontAwesomeIcon icon={faTriangleExclamation} color="var(--caution)" />
              <RMText type="sans" size="xs" color="caution" bold>
                We’ve found {issues.length} potential issue{issues.length > 1 ? 's' : ''} with this story
              </RMText>
              <ViewStoryIssuesButton onClick={onViewIssues}>
                <RMText type="sans" size="xs" color="caution" bold>
                  View
                </RMText>
              </ViewStoryIssuesButton>
            </StoryIssues>
          </StoryIssuesWrapper>
        )}
      </Content>
    </StyledStorySummary>
  );
}
