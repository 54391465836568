import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  getRMTextEditorStateText,
  RMTextEditorStore,
  setRMTextEditorValue,
} from '@/components/RMTextEditor/RMTextEditor.state';
import { stringToRMTextEditorContent } from '@/components/RMTextEditor/RMTextEditor.utils.js';
import { useServices } from '@/Services.js';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { useStoryIssues } from '@/services/api/story';

import { StoryIssuesDialog } from '../components/StoryIssuesDialog/index.js';
import { StorySummary } from '../components/StorySummary/StorySummary.js';
import { StorySummaryForm } from '../forms/story-summary.form.js';
import { StoriesManager } from '../states/stories.manager.js';
import { setStoryState, StoryManager, useStoryState } from '../states/story.manager.js';

export interface StorySummaryContainerProps {
  storyId: string;
  storiesManager: StoriesManager;
  storyManager: StoryManager;
  summaryForm: StorySummaryForm;
  titleTextEditor: RMTextEditorStore;
  summaryTextEditor: RMTextEditorStore;
}

export function StorySummaryContainer({
  storyId,
  storiesManager,
  storyManager,
  summaryForm,
  titleTextEditor,
  summaryTextEditor,
}: StorySummaryContainerProps) {
  const { storyViewerAnalyticsService } = useServices();
  const user = useUser();
  const storyState = useStoryState(storyManager);

  const state = useMemo(() => {
    if (storyState.type === 'editing' && storyState.assistant != null) {
      return 'regenerating';
    }

    if (storyState.type === 'editing') {
      return 'editing';
    }

    return 'viewing';
  }, [storyState]);

  const handleStarEditingSummary = useCallback(() => {
    setStoryState(storyManager, { type: 'editing', action: 'edit-summary' });
  }, [storyManager]);

  // Issues state
  const [issuesDialogOpen, setIssuesDialogOpen] = useState(false);
  const issues = useStoryIssues(storiesManager.showIssues ? storyId : null);

  // Learn more state
  const handleLearnMore = useCallback(() => {
    window.open('https://remento.co', '_blank');
  }, []);

  // Removes line breaks from the title
  useEffect(() => {
    return titleTextEditor.subscribe((state) => {
      const titleText = getRMTextEditorStateText(state);
      if (titleText.includes('\n')) {
        setRMTextEditorValue(titleTextEditor, stringToRMTextEditorContent(titleText.replaceAll('\n', '')));
      }
    });
  }, [titleTextEditor]);

  const handleOpenRegenerate = useCallback(async () => {
    setStoryState(storyManager, { ...storyState, type: 'editing', assistant: 'visible' });
    storyViewerAnalyticsService.onStoryRegenerateOpened();
  }, [storyManager, storyState, storyViewerAnalyticsService]);

  return (
    <>
      <StorySummary
        form={summaryForm}
        titleTextEditor={titleTextEditor}
        summaryTextEditor={summaryTextEditor}
        state={state}
        saving={storyState.type === 'editing' && storyState.isSaving === true}
        showLearnMore={user == null}
        issues={issues?.map((i) => i.type) ?? null}
        onViewIssues={() => setIssuesDialogOpen(true)}
        onOpenRegenerate={handleOpenRegenerate}
        onStartEditing={handleStarEditingSummary}
        onLearnMore={handleLearnMore}
      />

      <StoryIssuesDialog
        open={issuesDialogOpen}
        issues={(issues ?? []).map((i) => i.type)}
        onClose={() => setIssuesDialogOpen(false)}
      />
    </>
  );
}
