import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getStoryTitle } from '@remento/utils/entity/story';
import dayjs from 'dayjs';

import { RMTextEditorStore } from '@/components/RMTextEditor/RMTextEditor.state';
import { useIsMobileOrTabletViewport } from '@/hooks/useIsMobileViewport';
import { getQueryParam } from '@/hooks/useQueryParam';
import { useRecordingQuery } from '@/services/api/recording';
import { useStoryQuery } from '@/services/api/story';

import { useEnterFullscreen, useExitFullscreen } from '../../media-player/hooks/useFullscreen.js';
import { StoryPlayer } from '../components/StoryPlayer/StoryPlayer.js';
import { StoryRegenerateForm } from '../forms/story-regenerate.form.js';
import { StorySummaryForm } from '../forms/story-summary.form.js';
import { useStoryImageUrl } from '../hooks/useStoryImage.js';
import { useStoryVideoAuthor, useStoryVideoType } from '../hooks/useStoryVideo.js';
import { StoriesManager } from '../states/stories.manager.js';
import { setStoryState, StoryManager, useStoryState } from '../states/story.manager.js';

import { StoryActionsContainer, StoryActionsContainerRef } from './StoryActions.container.js';
import { StoryRegenerateContainer } from './StoryRegenerateContainer.js';
import { StorySummaryContainer } from './StorySummary.container.js';

export interface StoryPlayerContainerProps {
  storyId: string;
  storiesManager: StoriesManager;
  storyManager: StoryManager;
  summaryForm: StorySummaryForm;
  titleTextEditor: RMTextEditorStore;
  summaryTextEditor: RMTextEditorStore;
  regenerateForm: StoryRegenerateForm;
  promptText: string;
  onMouseOver: (hovering: boolean) => void;
  onViewHighlightReel?: () => void;
}

export function StoryPlayerContainer({
  storyId,
  storiesManager,
  storyManager,
  summaryForm,
  titleTextEditor,
  summaryTextEditor,
  regenerateForm,
  promptText,
  onMouseOver,
  onViewHighlightReel,
}: StoryPlayerContainerProps) {
  const imageUrl = useStoryImageUrl(storyId);
  const isMobileOrTablet = useIsMobileOrTabletViewport();

  //  Queries
  const recordingType = useStoryVideoType(storyId);
  const author = useStoryVideoAuthor(storyId);

  const storyState = useStoryState(storyManager);
  const controlsVisible = storyState.type !== 'view' || storyState.controls === 'visible';

  const storyQuery = useStoryQuery(storyId);
  const recordingQuery = useRecordingQuery(storyQuery.data?.recordingsIds[0]);
  const recordedDate = useMemo(
    () => dayjs(recordingQuery.data?.audit.create.date).format('MMM D, YYYY'),
    [recordingQuery.data?.audit.create.date],
  );

  const handleToggleControlsVisibility = useCallback(() => {
    if (isMobileOrTablet == false) {
      return;
    }

    if (storyState.type === 'view') {
      setStoryState(storyManager, {
        type: 'view',
        controls: storyState.controls === 'visible' ? 'hidden' : 'visible',
      });
    }
  }, [isMobileOrTablet, storyManager, storyState]);

  // Primary media
  const enterFullscreen = useEnterFullscreen();
  const exitFullscreen = useExitFullscreen();
  const [primaryMedia, setPrimaryMedia] = useState<'video' | 'photo'>(() => {
    const primaryMediaQueryParam = getQueryParam('primary-media');
    if (primaryMediaQueryParam == 'photo' || primaryMediaQueryParam == 'video') {
      return primaryMediaQueryParam;
    }

    return 'photo';
  });

  const handleChangePrimaryMedia = useCallback(
    (newPrimaryMedia: 'video' | 'photo') => {
      setPrimaryMedia(newPrimaryMedia);
      enterFullscreen();
    },
    [enterFullscreen],
  );

  useEffect(() => {
    if (recordingType == 'audio') {
      setPrimaryMedia('photo');
    }
  }, [recordingType]);

  // Always change the primary media to video when editing.
  useEffect(() => {
    if (storyState.type === 'editing') {
      setPrimaryMedia('video');
      return;
    }
    if (storyState.type === 'view' && recordingType === 'audio') {
      setPrimaryMedia('photo');
    }
  }, [recordingType, storyState]);

  // Story actions ref
  const storyActionsRef = useRef<StoryActionsContainerRef>(null);

  return (
    <StoryPlayer
      type={recordingType ?? 'video'}
      primaryMedia={primaryMedia}
      imageUrl={imageUrl}
      authorInitials={author.initials}
      authorAvatarUrl={author.avatarUrl}
      controlsVisible={controlsVisible}
      promptText={promptText}
      recordedDate={recordedDate}
      title={getStoryTitle(storyQuery.data) ?? ''}
      editing={storyState.type === 'editing'}
      isRegenerating={storyState.type === 'editing' && storyState.assistant != null}
      isReacting={storyState.type === 'reacting'}
      onChangePrimaryMedia={handleChangePrimaryMedia}
      onToggleControlsVisibility={handleToggleControlsVisibility}
      onMouseOver={onMouseOver}
      onExitFullscreen={exitFullscreen}
      onViewHighlightReel={onViewHighlightReel}
      onUpdatePhoto={() => storyActionsRef.current?.updatePhoto()}
      onRemovePhoto={() => storyActionsRef.current?.removePhoto()}
      StorySummary={
        <StorySummaryContainer
          storyId={storyId}
          storiesManager={storiesManager}
          storyManager={storyManager}
          summaryForm={summaryForm}
          titleTextEditor={titleTextEditor}
          summaryTextEditor={summaryTextEditor}
        />
      }
      StoryActions={
        <StoryActionsContainer
          ref={storyActionsRef}
          storyId={storyId}
          storiesManager={storiesManager}
          storyManager={storyManager}
          summaryForm={summaryForm}
          titleTextEditor={titleTextEditor}
          summaryTextEditor={summaryTextEditor}
        />
      }
      StoryRegenerate={
        <StoryRegenerateContainer
          storyId={storyId}
          storyManager={storyManager}
          summaryForm={summaryForm}
          titleTextEditor={titleTextEditor}
          summaryTextEditor={summaryTextEditor}
          regenerateForm={regenerateForm}
        />
      }
    />
  );
}
