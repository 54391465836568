import { PropsWithChildren } from 'react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import * as Tooltip from '@radix-ui/react-tooltip';

import { useIsDesktopViewport } from '@/hooks/useIsMobileViewport.js';

import { CloseButton, TooltipBody, TooltipText, TooltipTextContent } from './RMHintTooltip.styles.js';

export type RMHintTooltipProps = PropsWithChildren<{
  open: boolean;
  title: string;
  extraAction?: string | null;
  onAction?: () => void;
  onClose: () => void;
}>;

export function RMHintTooltip({ open, title, extraAction, onAction, onClose, children }: RMHintTooltipProps) {
  const isDesktop = useIsDesktopViewport();

  return (
    <Tooltip.Provider delayDuration={0.2}>
      <Tooltip.Root open={open}>
        <Tooltip.Trigger asChild>
          <div>{children}</div>
        </Tooltip.Trigger>

        <Tooltip.Content
          sideOffset={12}
          align="end"
          style={{ zIndex: 999 }}
          onClick={() => {
            if (isDesktop) {
              return;
            }
            return onClose();
          }}
        >
          <TooltipBody
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: 'easeOut', duration: 0.2 }}
          >
            <TooltipTextContent>
              <TooltipText type="sans" size="m" color="surface-brightest" bold>
                {title}
              </TooltipText>
              {extraAction != null && onAction != null && (
                <TooltipText
                  type="sans"
                  size="xs"
                  color="inverse-on-surface-primary"
                  underline
                  cursor="pointer"
                  onClick={onAction}
                >
                  {extraAction}
                </TooltipText>
              )}
            </TooltipTextContent>
            <CloseButton
              tooltip={null}
              icon={faXmark}
              backgroundColor="transparent"
              color="white"
              size="sm"
              data-extra-action={extraAction != null}
              onClick={onClose}
            />
          </TooltipBody>
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
