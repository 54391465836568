import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createLogger } from '@remento/logger';
import { ConflictError, ConflictErrorType } from '@remento/types/error';

import { toast } from '@/components/RMToast/RMToast.js';
import { submitForm } from '@/modules/form/form.js';
import { setInputValues } from '@/modules/form/input.js';
import { getSigninPath } from '@/modules/routing/paths.js';
import { useServices } from '@/Services.js';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook.js';
import { usePersonQuery } from '@/services/api/person/person.service.hook.js';
import { useStoryQuery } from '@/services/api/story/story.service.hook.js';
import { captureException } from '@/utils/captureException.js';

import { RequestAccessDialog } from '../components/RequestAccessDialog/RequestAccessDialog.js';
import { createRequestAccessForm } from '../forms/request-access.form.js';

const logger = createLogger('request-access-dialog-container');

export interface RequestAccessDialogContainerProps {
  storyId: string;
  onClose: () => void;
}

export function RequestAccessDialogContainer({ storyId, onClose }: RequestAccessDialogContainerProps) {
  // Services
  const { redirectService, projectCacheService, collaborationAnalyticsService, browserAnalytics } = useServices();
  const navigate = useNavigate();
  const signOut = useSignOut();

  // Queries
  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);
  const storyQuery = useStoryQuery(storyId);

  // State
  const requestAccessForm = useMemo(() => createRequestAccessForm(), []);
  const [succeeded, setSucceeded] = useState(false);

  // Callbacks
  const handleRequestAccess = useCallback(async () => {
    if (storyQuery.data == null) {
      return;
    }

    await submitForm(requestAccessForm, async (data) => {
      try {
        await projectCacheService.requestAccess(storyQuery.data.projectId, data);

        if (user == null) {
          await browserAnalytics
            .identify({ type: 'email', value: data.email }, 'book-request-project-access', data)
            .catch((error) => logger.error('IDENTIFY_FAILED', () => ({ error })));
        }

        setSucceeded(true);
        setTimeout(() => {
          onClose();
          setSucceeded(false);
        }, 2500);
        collaborationAnalyticsService.onAccessRequested('story-standalone', 'edit-story');
      } catch (error) {
        if (error instanceof ConflictError && error.data?.type == ConflictErrorType.USER_ALREADY_EXISTS) {
          toast('A user already exists with this email. Please sign in.', 'root-toast', 'error');
          return;
        }
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    });
  }, [
    browserAnalytics,
    collaborationAnalyticsService,
    onClose,
    projectCacheService,
    requestAccessForm,
    storyQuery.data,
    user,
  ]);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
    navigate(getSigninPath({ backupLocalData: true }));
  }, [navigate, redirectService]);

  const handleSignOut = useCallback(async () => {
    await redirectService.registerRedirect('signed-out', window.location.pathname + window.location.search);
    await signOut();
  }, [redirectService, signOut]);

  useEffect(() => {
    setInputValues(requestAccessForm, {
      firstName: personQuery.data?.name?.first ?? '',
      lastName: personQuery.data?.name?.last ?? '',
      email: user?.communicationChannels.email ?? '',
    });
  }, [personQuery.data, requestAccessForm, user]);

  return (
    <RequestAccessDialog
      open
      form={requestAccessForm}
      disabled={user != null}
      isSignedIn={user != null}
      signedInUserName={personQuery.data?.name?.first ?? null}
      success={succeeded}
      onRequestAccess={handleRequestAccess}
      onSignIn={handleSignIn}
      onSignOut={handleSignOut}
      onClose={onClose}
    />
  );
}
