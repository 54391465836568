import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactionSentiment, ReactionStatus } from '@remento/types/reaction';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { toast } from '@/components/RMToast/RMToast';
import { getQueryParam } from '@/hooks/useQueryParam';
import { resetForm, submitForm } from '@/modules/form/form';
import { getInputInteraction, getInputValue, setInputValue } from '@/modules/form/input';
import { getSigninPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { StoryPage } from '@/services/analytics/story-viewer-analytics/story-viewer-analytics.types';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useCanReact, useReactionsQuery, useReactionsSuggestionsQuery } from '@/services/api/reaction';
import { useRecordingQuery } from '@/services/api/recording';
import { captureException } from '@/utils/captureException';
import { secureUuid } from '@/utils/uuid';

import { StoryReactionDialog } from '../components/StoryReactionDialog/StoryReactionDialog';
import { createStoryReactionForm } from '../forms/story-reaction.form';
import {
  REACTION_MESSAGE_PARAM_NAME,
  REACTION_SENTIMENT_PARAM_NAME,
  useSyncStoryReactionForm,
} from '../hooks/useSyncStoryReactionForm';

export interface StoryReactionDialogContainerProps {
  open: boolean;
  recordingId: string;
  initialSentiment?: ReactionSentiment | null;
  page: StoryPage;
  onClose: () => void;
}

const INITIAL_STATE: StoryReactionDialogLoadingState = {
  type: 'loading',
};

const EMPTY_SUGGESTIONS = {
  [ReactionSentiment.LOVE]: '',
  [ReactionSentiment.FUNNY]: '',
  [ReactionSentiment.SURPRISING]: '',
  [ReactionSentiment.TOUCHING]: '',
};

export interface StoryReactionDialogInputState {
  type: 'input';
  suggestions: Record<ReactionSentiment, string>;
}

export interface StoryReactionDialogLoadingState {
  type: 'loading';
}

export interface StoryReactionDialogSubmittedState {
  type: 'submitted';
}

export type StoryReactionDialogState =
  | StoryReactionDialogInputState
  | StoryReactionDialogLoadingState
  | StoryReactionDialogSubmittedState;

export function StoryReactionDialogContainer({
  open,
  recordingId,
  initialSentiment,
  page,
  onClose,
}: StoryReactionDialogContainerProps) {
  const { reactionService, reactionCacheService, redirectService, storyViewerAnalyticsService } = useServices();
  const navigate = useNavigate();

  // States
  const [reactionCreated, setReactionCreated] = useState(false);
  const [state, setState] = useState<StoryReactionDialogState>(INITIAL_STATE);
  const [desiredSentiment, setDesiredSentiment] = useState<ReactionSentiment | null>(null);
  // Reset the form when the recording ID changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reactionForm = useMemo(() => createStoryReactionForm(), [recordingId]);
  const { clearStoryReactionFormParams } = useSyncStoryReactionForm(reactionForm);

  // Queries
  const user = useUser();
  const personId = user?.personId;

  const recordingQuery = useRecordingQuery(recordingId);
  const storytellerPersonQuery = usePersonQuery(recordingQuery.data?.personId ?? null);
  const storytellerName = storytellerPersonQuery.data?.name?.first || '';

  const canReact = useCanReact(recordingId);
  const reactionsQuery = useReactionsQuery(canReact ? recordingId : null);
  const reactionsSuggestionsQuery = useReactionsSuggestionsQuery(canReact ? recordingId : null);
  const suggestionIds = reactionsSuggestionsQuery?.data;

  // EFFECT TO CLEAN UP THE DIALOG'S STATE WHEN IT'S CLOSED
  useEffect(() => {
    if (open) {
      return;
    }
    resetForm(reactionForm);
    setState(INITIAL_STATE);
  }, [open, reactionForm, state]);

  // EFFECT TO INITIALIZE THE STATE WHEN THE DIALOG IS OPENED
  useEffect(() => {
    const initSuggestions = async () => {
      if (!open || suggestionIds == null) {
        return;
      }

      const paramSentiment = getQueryParam(REACTION_SENTIMENT_PARAM_NAME) as ReactionSentiment;
      const paramMessage = getQueryParam(REACTION_MESSAGE_PARAM_NAME);

      const allSuggestions = await Promise.all(
        suggestionIds.map((id) => {
          return reactionCacheService.getReactionSuggestion(id);
        }),
      );

      // Map random suggestion for each sentiment
      const sentiments = Object.keys(ReactionSentiment) as ReactionSentiment[];
      const suggestions = {} as Record<ReactionSentiment, string>;
      sentiments.forEach((sentiment) => {
        const sentimentSuggestions = allSuggestions.filter(
          (suggestion) => suggestion?.message && suggestion.sentiment === sentiment,
        );
        const random = Math.floor(Math.random() * sentimentSuggestions.length);
        suggestions[sentiment] = sentimentSuggestions[random]?.message ?? '';
      });

      const sentiment = initialSentiment ?? paramSentiment ?? ReactionSentiment.LOVE;
      const message = paramMessage ?? suggestions[sentiment] ?? '';

      if (sentiment || allSuggestions.length > 0) {
        setState({
          type: 'input',
          suggestions,
        });
      } else {
        setState({ type: 'input', suggestions: EMPTY_SUGGESTIONS });
      }

      if (sentiment) {
        setInputValue(reactionForm, 'sentiment', sentiment);
      }

      if (message != null && message.length > 0) {
        setInputValue(reactionForm, 'message', message);
      }
    };

    initSuggestions();
  }, [open, suggestionIds, reactionCacheService, reactionForm, initialSentiment]);

  // EFFECT TO SUBMIT A REACTION WHEN THE DIALOG IS OPENED
  useEffect(() => {
    if (
      !open ||
      personId == null ||
      reactionCreated ||
      reactionsQuery.data?.length ||
      !recordingId ||
      user === null ||
      !canReact
    ) {
      return;
    }

    const paramSentiment = getQueryParam(REACTION_SENTIMENT_PARAM_NAME) as ReactionSentiment;

    const reactionId = secureUuid();
    const reaction =
      paramSentiment == null
        ? { id: reactionId, status: ReactionStatus.PENDING, personId, recordingId }
        : { id: reactionId, status: ReactionStatus.DELAYED, sentiment: paramSentiment, personId, recordingId };
    reactionService.createReaction(reaction).catch((err) => captureException(err));
    setReactionCreated(true);
  }, [recordingId, personId, reactionService, reactionCreated, reactionsQuery.data, user, canReact, open]);

  // EFFECT TO AUTO-CLOSE THE DIALOG ONCE IT'S BEEN SUBMITTED
  useEffect(() => {
    if (state.type != 'submitted') return;
    const timeoutHandle = setTimeout(onClose, 5000);
    return () => clearTimeout(timeoutHandle);
  }, [state.type, onClose]);

  const handleSentimentChange = useCallback(
    (sentiment: ReactionSentiment) => {
      if (sentiment === getInputValue(reactionForm, 'sentiment')) {
        return;
      }

      if (state.type !== 'input') {
        // This is mostly to make typescript happy. This callback cannot be called if the state is not set to 'input'
        throw new Error('Expected to the state to be set to "input"');
      }

      const isMessageEdited = getInputInteraction(reactionForm, 'message').isDirty;

      if (isMessageEdited && desiredSentiment !== sentiment) {
        setDesiredSentiment(sentiment);
      } else {
        resetForm(reactionForm);
        setInputValue(reactionForm, 'sentiment', sentiment ?? '');
        setInputValue(reactionForm, 'message', state.suggestions[sentiment] ?? '');

        setDesiredSentiment(null);
      }
    },
    [state, reactionForm, desiredSentiment],
  );

  // open and close dialog based on store state
  useEffect(() => {
    if (open) {
      const reaction = getQueryParam('reaction');
      if (reaction) {
        storyViewerAnalyticsService.onReactionArrived('email');
      } else {
        storyViewerAnalyticsService.onReactionArrived('story view');
      }
    } else {
      setReactionCreated(false);
    }
  }, [open, storyViewerAnalyticsService]);

  const handleSubmitReaction = async () => {
    if (!personId) return;

    try {
      await submitForm(reactionForm, async ({ message, sentiment }) => {
        await reactionCacheService.createReaction({
          id: secureUuid(),
          status: ReactionStatus.SUBMITTED,
          sentiment: sentiment as ReactionSentiment,
          message,
          personId,
          recordingId,
        });

        clearStoryReactionFormParams();

        const textCustomized = getInputInteraction(reactionForm, 'message').isDirty;
        storyViewerAnalyticsService.onReactionSent(page, sentiment ?? null, textCustomized);
        setState({ type: 'submitted' });
      });
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred', 'root-toast', 'error');
    }
  };

  const handleDiscardChanges = useCallback(() => {
    if (!desiredSentiment) return;

    handleSentimentChange(desiredSentiment);
    setState({
      ...(state as StoryReactionDialogInputState),
      type: 'input',
    });
  }, [handleSentimentChange, desiredSentiment, state]);

  const handleCancelDiscardChanges = useCallback(() => {
    setDesiredSentiment(null);
  }, []);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
    navigate(getSigninPath());
  }, [redirectService, navigate]);

  if (user === null && open) {
    return (
      <RMConfirmationModal
        open={true}
        type="primary"
        title="Sign in required"
        message="You must sign in to watch and react to this story."
        confirmLabel="Sign in"
        cancelLabel={null}
        onConfirm={handleSignIn}
      />
    );
  }

  if (!open || !canReact) {
    return null;
  }

  return (
    <>
      <StoryReactionDialog
        showSentiments
        firstName={storytellerName}
        open={open && !desiredSentiment}
        form={reactionForm}
        state={state.type}
        onChangeSentiment={handleSentimentChange}
        onConfirm={handleSubmitReaction}
        onClose={onClose}
      />
      <RMConfirmationModal
        open={desiredSentiment !== null}
        type="danger"
        title="Discard message?"
        message="Selecting a new reaction will discard your message."
        confirmLabel="Yes, discard"
        cancelLabel="Nevermind"
        onConfirm={handleDiscardChanges}
        onCancel={handleCancelDiscardChanges}
      />
    </>
  );
}
