import { styled } from '@linaria/react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';

export const StyledStoryActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: var(--spacing-md);

  background: var(--surface);
  border-top: 1px solid var(--border-weak);
  padding: var(--spacing-xs) var(--spacing-md);

  body[data-mobile='false'][data-tablet='false'] & {
    padding: var(--spacing-md) var(--spacing-xl);
  }

  body[data-mobile='false'][data-tablet='false'] & {
    border-bottom-right-radius: var(--radius-smooth);
  }
`;

export const Button = styled(RMButton)`
  min-width: 100px !important;
`;

export const ReactButton = styled(RMIconButton)`
  margin-right: auto;
`;
