import { useMemo } from 'react';
import { faImage, faRightLeft } from '@fortawesome/pro-regular-svg-icons';
import { faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faDownload, faRotateRight, faSparkles, faTrashCan, faXmark } from '@fortawesome/pro-solid-svg-icons';

import { BottomSheetOption, RMBottomSheet } from '@/components/RMBottomSheet';
import { RMHintTooltip } from '@/components/RMHintTooltip/RMHintTooltip.js';
import { useIsFullscreen } from '@/hooks/useIsFullscreen';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport.js';

import { Button, ReactButton, StyledStoryActions } from './StoryActions.styles.js';

export interface StoryActionsViewingState {
  type: 'viewing';
  showEditingHint: boolean;
}

export interface StoryActionsEditingState {
  type: 'editing';
}

export interface StoryActionsEditingTextState {
  type: 'editing-text';
  isSaving: boolean;
}

export type StoryActionsState = StoryActionsViewingState | StoryActionsEditingState | StoryActionsEditingTextState;

export interface StoryActionsProps {
  state: StoryActionsState;
  canDownload: boolean;
  canReact: boolean;
  hasPhoto: boolean;
  onReact: () => void;
  onShare: () => void;
  onEnterEditMode: () => void;
  onExitEditMode: () => void;
  onReRecord: () => void;
  onDownload: () => void;
  onRemove: () => void;
  onUpdatePhoto: () => void;
  onRemovePhoto: () => void;
  onOpenRegenerate: () => void;
  onSaveSummary: () => void;
  onDiscardSummary: () => void;
  onEditHintTooltipClose: () => void;
  onEditHintTooltipLearnMore: () => void;
}

export function StoryActions({
  state,
  canDownload,
  canReact,
  hasPhoto,
  onReact,
  onShare,
  onEnterEditMode,
  onExitEditMode,
  onReRecord,
  onDownload,
  onRemove,
  onUpdatePhoto,
  onRemovePhoto,
  onOpenRegenerate,
  onSaveSummary,
  onDiscardSummary,
  onEditHintTooltipClose,
  onEditHintTooltipLearnMore,
}: StoryActionsProps) {
  const isFullscreen = useIsFullscreen();
  const isMobile = useIsMobileViewport();

  const moreOptions = useMemo<BottomSheetOption[]>(() => {
    return [
      {
        type: 'item',
        label: 'Re-record story',
        value: 'rerecord',
        icon: faRotateRight,
        onSelect: onReRecord,
      } as const,
      {
        type: 'item',
        label: 'Download recording',
        value: 'download',
        icon: faDownload,
        disabled: !canDownload,
        onSelect: onDownload,
      } as const,
      { type: 'separator' } as const,
      {
        type: 'item',
        label: 'AI writing tool',
        value: 'regenerate',
        icon: faSparkles,
        onSelect: onOpenRegenerate,
      } as const,
      { type: 'separator' } as const,
      ...(hasPhoto
        ? [
            {
              type: 'item',
              label: 'Change photo',
              value: 'change-photo',
              icon: faRightLeft,
              onSelect: onUpdatePhoto,
            } as const,
            {
              type: 'item',
              label: 'Remove photo',
              value: 'remove-photo',
              icon: faImage,
              destructive: true,
              onSelect: onRemovePhoto,
            } as const,
          ]
        : [
            {
              type: 'item',
              label: 'Add photo',
              value: 'add-photo',
              icon: faImage,
              onSelect: onUpdatePhoto,
            } as const,
          ]),
      { type: 'separator' } as const,
      {
        type: 'item',
        label: 'Delete story',
        value: 'delete-story',
        icon: faTrashCan,
        destructive: true,
        onSelect: onRemove,
      } as const,
    ];
  }, [canDownload, hasPhoto, onDownload, onOpenRegenerate, onReRecord, onRemove, onRemovePhoto, onUpdatePhoto]);

  if (isFullscreen) {
    return null;
  }

  switch (state.type) {
    case 'viewing':
      return (
        <StyledStoryActions>
          {canReact && (
            <ReactButton
              tooltip={{ label: 'React', position: 'top' }}
              backgroundColor="white"
              onClick={onReact}
              icon={faHeart}
            />
          )}
          <Button onClick={onShare}>Share</Button>
          <RMHintTooltip
            open={state.showEditingHint}
            title="Ready to edit?"
            extraAction={isMobile ? null : 'Learn more'}
            onClose={onEditHintTooltipClose}
            onAction={onEditHintTooltipLearnMore}
          >
            <Button background="primary" onClick={onEnterEditMode}>
              Make changes
            </Button>
          </RMHintTooltip>
        </StyledStoryActions>
      );
    case 'editing':
      return (
        <StyledStoryActions>
          <RMBottomSheet Trigger={<Button>More</Button>} options={moreOptions} />

          <Button background="primary" onClick={onExitEditMode}>
            Done
          </Button>
        </StyledStoryActions>
      );
    case 'editing-text':
      return (
        <StyledStoryActions>
          <Button leftIcon={faXmark} disabled={state.isSaving} onClick={onDiscardSummary}>
            Cancel
          </Button>
          <Button background="primary" leftIcon={faCheck} autoLoading={state.isSaving} onClick={onSaveSummary}>
            Update story
          </Button>
        </StyledStoryActions>
      );
  }
}
