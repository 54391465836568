import { forwardRef } from 'react';

import { RMTextEditor, RMTextEditorProps, RMTextEditorRef } from './RMTextEditor.js';
import { RMTextEditorStore, setRMTextEditorValue, useRMTextEditorValue } from './RMTextEditor.state.js';

export type RMTextEditorControllerProps = Omit<RMTextEditorProps, 'value'> & {
  store: RMTextEditorStore;
};

export const RMTextEditorController = forwardRef<RMTextEditorRef, RMTextEditorControllerProps>(
  ({ store, ...props }, ref) => {
    const value = useRMTextEditorValue(store);

    return (
      <RMTextEditor
        {...props}
        ref={ref}
        value={value}
        onChange={(newValue) => {
          setRMTextEditorValue(store, newValue);
          props.onChange?.(newValue);
        }}
      />
    );
  },
);
