import { useCallback } from 'react';
import {
  AssetAlternativeMetadataType,
  BaseAssetAlternativeType,
  RecordingAssetAlternativeType,
} from '@remento/types/alternative';
import { getStoryDownloadName, getStoryTitle } from '@remento/utils/entity/story';

import { removeToast, toast } from '@/components/RMToast/RMToast';
import { logger } from '@/logger';
import { SocialShareDialog } from '@/modules/stories/components/SocialShareDialog/SocialShareDialog';
import { StoriesManager, useActiveStoryId } from '@/modules/stories/states/stories.manager';
import { setStoryState, StoryManager, useStoryState } from '@/modules/stories/states/story.manager';
import { useServices } from '@/Services';
import { useAlternativeType, useAssetAlternativesQuery } from '@/services/api/asset';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';
import { useStoryQuery, useStoryShareLinkQuery } from '@/services/api/story';
import { captureException } from '@/utils/captureException';
import { genitiveCase } from '@/utils/genitiveCase';
import { openShareSheet, showShareSheetToast } from '@/utils/share-sheet';

import { StoryShareDialog } from '../components/StoryShareDialog/StoryShareDialog.js';

export interface StoryShareDialogContainerProps {
  storiesManager: StoriesManager;
  storyManager: StoryManager;
}

export function StoryShareDialogContainer({ storiesManager, storyManager }: StoryShareDialogContainerProps) {
  const { storyCacheService, assetService, storyViewerAnalyticsService } = useServices();

  const storyState = useStoryState(storyManager);
  const storiesType = storiesManager.type;

  // Entities
  const storyId = useActiveStoryId(storiesManager);
  const storyQuery = useStoryQuery(storyId);
  const storyShareLinkQuery = useStoryShareLinkQuery(storyId);
  const shareLink = storyShareLinkQuery.data ?? '';

  const user = useUser();
  const projectQuery = useProjectQuery(user != null ? storyQuery.data?.projectId : null);
  const storytellerPersonQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);
  const storytellerFirstName = storytellerPersonQuery.data?.name?.first;

  // Standalone Share
  const alternativesQuery = useAssetAlternativesQuery(storyQuery.data?.recordingsIds[0]);
  const alternative = useAlternativeType(
    alternativesQuery.data,
    storiesType === 'highlight-reel-standalone'
      ? RecordingAssetAlternativeType.HIGHLIGHT_REEL
      : BaseAssetAlternativeType.ORIGINAL,
    AssetAlternativeMetadataType.VIDEO,
  );

  const handleCopyShareLink = useCallback(async () => {
    if (!shareLink) {
      return;
    }

    const shareResult = await openShareSheet({ title: 'Remento', url: shareLink });
    showShareSheetToast(shareResult);
    storyViewerAnalyticsService.onStoryShared(
      storiesManager.type,
      shareResult == 'copied-to-clipboard' ? 'clipboard' : 'share-sheet',
    );
  }, [shareLink, storiesManager.type, storyViewerAnalyticsService]);

  const handleRegenerateShareLink = useCallback(async () => {
    try {
      if (!storyId) {
        return;
      }

      await storyCacheService.regenerateShareLink(storyId);
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [storyCacheService, storyId]);

  const handleDownload = useCallback(async () => {
    if (alternative == null) {
      logger.warn('VIDEO_NOT_LOADED');
      return;
    }

    const toastId = toast('Downloading', 'root-toast', 'default', {
      isLoading: true,
    });

    try {
      const filename = getStoryDownloadName(storyQuery.data, storiesType === 'highlight-reel-standalone') + '.mp4';
      const downloadUrl = await assetService.getAlternativeDownloadVideoUrl(alternative.id, filename);
      window.location.assign(downloadUrl);
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    } finally {
      removeToast(toastId);
    }
  }, [alternative, storyQuery.data, storiesType, assetService]);

  const storyTitle = getStoryTitle(storyQuery.data);
  const handleShareAction = useCallback(
    async (source: 'email' | 'facebook' | 'twitter' | 'whatsapp' | 'download') => {
      if (source === 'download') {
        storyViewerAnalyticsService.onStoryShared(storiesManager.type, 'download');
        handleDownload();
        return;
      }

      switch (source) {
        case 'email': {
          location.href = `mailto:?subject=Watch ${genitiveCase(storytellerFirstName)} story highlights: ${
            storyTitle ?? ''
          }&body=Watch it on Remento - ${shareLink}`;
          break;
        }
        case 'facebook': {
          storyViewerAnalyticsService.onStoryShared(storiesManager.type, 'facebook');
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`, '_blank');
          break;
        }
        case 'twitter': {
          storyViewerAnalyticsService.onStoryShared(storiesManager.type, 'twitter');
          window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareLink)}&text=`, '_blank');
          break;
        }
        case 'whatsapp': {
          storyViewerAnalyticsService.onStoryShared(storiesManager.type, 'whatsapp');
          window.open(`https://api.whatsapp.com/send/?text=${encodeURIComponent(shareLink)}&type=custom_url`, '_blank');
          break;
        }
      }
    },
    [handleDownload, shareLink, storiesManager.type, storyTitle, storyViewerAnalyticsService, storytellerFirstName],
  );

  // Dialog state
  const open = storyState.type === 'sharing';
  const handleClose = useCallback(() => {
    setStoryState(storyManager, { type: 'view', controls: 'visible' });
  }, [storyManager]);

  if (storiesType === 'story-standalone' || storiesType === 'highlight-reel-standalone') {
    return (
      <SocialShareDialog
        open={open}
        shareLink={shareLink}
        onShareAction={handleShareAction}
        onCopyShareLink={handleCopyShareLink}
        onClose={handleClose}
      />
    );
  }

  return (
    <StoryShareDialog
      open={open}
      shareLink={shareLink ?? 'Loading...'}
      onCopyShareLink={handleCopyShareLink}
      onRegenerateShareLink={handleRegenerateShareLink}
      onClose={handleClose}
    />
  );
}
