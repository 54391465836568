import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal';
import { faRightLeft } from '@fortawesome/pro-regular-svg-icons';
import {
  faCirclePlus,
  faImage,
  faSparkles,
  faUpRightAndDownLeftFromCenter,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { AnimatePresence, easeInOut } from 'framer-motion';

import { BottomSheetOption, RMBottomSheet } from '@/components/RMBottomSheet/RMBottomSheet.js';
import { RMText } from '@/components/RMText/RMText';
import { useElementSize } from '@/hooks/useElementSize.js';
import { useIsFullscreen } from '@/hooks/useIsFullscreen';
import { useIsMobileOrTabletViewport, useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useVisualViewportSize } from '@/hooks/useVisualViewportSize.js';
import { PlayerScrubber } from '@/modules/media-player/PlayerScrubber';
import { scrollCaretIntoView } from '@/utils/scroll.js';

import { StoryAudioPlayer } from '../StoryAudioPlayer/StoryAudioPlayer.js';
import { StoryVideoPlayer } from '../StoryVideoPlayer/StoryVideoPlayer.js';

import {
  AbsoluteMediaContainer,
  ActionsMobileWrapper,
  BackgroundImage,
  Content,
  FullscreenCloseButton,
  FullscreenStoryTitle,
  HighlightsReelButton,
  Image,
  MaximizeButton,
  MediaContainer,
  MediaWrapper,
  MobileHighlightsReelButtonWrapper,
  OutPortalWrapper,
  PipImageBottomSheetWrapper,
  PipImageEditButton,
  PipImageWrapper,
  PipWrapper,
  RegenerateDesktopWrapper,
  ScrollArea,
  StyledStoryPlayer,
  SummaryAndActionsDesktopWrapper,
  TopOverlay,
} from './StoryPlayer.styles.js';
import { StoryPlayerPlayButton } from './StoryPlayerPlayButton.js';

interface StoryPlayerProps {
  type: 'audio' | 'video';
  primaryMedia: 'video' | 'photo';
  imageUrl: string | null;
  authorInitials: string;
  authorAvatarUrl: string | null;
  controlsVisible: boolean;
  StorySummary: ReactNode;
  StoryActions: ReactNode;
  StoryRegenerate: ReactNode;
  promptText: string;
  recordedDate?: string;
  title?: string;
  editing: boolean;
  isRegenerating: boolean;
  isReacting: boolean;
  onChangePrimaryMedia: (media: 'video' | 'photo') => void;
  onMouseOver: (hovering: boolean) => void;
  onToggleControlsVisibility: () => void;
  onExitFullscreen: () => void;
  onViewHighlightReel?: () => void;
  onUpdatePhoto: () => void;
  onRemovePhoto: () => void;
}

// We need to use framer to do this because in iOS safari, animating a transform animation
// can cause flickering in some cases.
function getMediaContainerAnimation(isMobileOrTablet: boolean, controlsVisible: boolean) {
  if (isMobileOrTablet) {
    return {
      y: `${controlsVisible ? 0 : 3.5}rem`,
    };
  }
  return {
    y: `${controlsVisible ? 0 : 6}rem`,
  };
}

export function StoryPlayer({
  type,
  primaryMedia,
  imageUrl,
  authorInitials,
  authorAvatarUrl,
  controlsVisible,
  StorySummary,
  StoryActions,
  StoryRegenerate,
  promptText,
  recordedDate,
  title,
  editing,
  isRegenerating,
  isReacting,
  onChangePrimaryMedia,
  onToggleControlsVisibility,
  onMouseOver,
  onExitFullscreen,
  onViewHighlightReel,
  onUpdatePhoto,
  onRemovePhoto,
}: StoryPlayerProps) {
  const isMobileOrTablet = useIsMobileOrTabletViewport();
  const isMobile = useIsMobileViewport();
  const isFullscreen = useIsFullscreen();

  const playerPortalNode = useMemo(() => createHtmlPortalNode(), []);

  const initialsSize = useMemo(() => {
    if (!imageUrl || editing) {
      return 'large';
    }

    return isMobile ? 'small' : 'medium';
  }, [imageUrl, editing, isMobile]);

  // Scroll down to the start of the title input when entering edit mode on mobile
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (editing && isMobileOrTablet) {
      // video height - actions height
      if (scrollRef.current != null) {
        scrollRef.current.scrollTop = 277 - 65;
      }
    }
  }, [editing, isMobileOrTablet]);

  // Screen size state
  const viewportSize = useVisualViewportSize();
  const [actionsWrapperRef, actionsSize] = useElementSize();
  const scrollAreaHeight = useMemo(() => {
    if (!isMobileOrTablet) {
      return undefined;
    }
    // We should limit the height of the scroll area to the height of the
    // viewport - actins.
    // This will make the actions sticky even if the keyboard is open.
    return `${viewportSize.height - actionsSize.height}px`;
  }, [actionsSize.height, isMobileOrTablet, viewportSize.height]);

  // Disable the html body scrolling when on mobile.
  // This is to improve the scrolling when the keyboard is open.
  useEffect(() => {
    if (!isMobileOrTablet || scrollRef.current == null) {
      return;
    }

    // Don't disable while regenerating or reacting. We may need the scroll in the preview.
    if (isRegenerating || isReacting) {
      return;
    }

    const scrollContainer = scrollRef.current;
    disableBodyScroll(scrollContainer);

    // Sometimes when focusing a input, iOS will automatically scroll the body element.
    // This will create a weird spacing that cannot be removed below the footer.
    // The scroll even does is not fired for this change, so we need to
    // periodically check for scrolls in the body.
    const bodyScrollCheckIntervalId = setInterval(() => {
      if (document.documentElement.scrollTop > 0) {
        document.documentElement.scrollTop = 0;
      }
    }, 250);

    return () => {
      enableBodyScroll(scrollContainer);
      clearInterval(bodyScrollCheckIntervalId);
    };
  }, [isMobileOrTablet, isReacting, isRegenerating]);

  // When the viewport changes, reset the scroll to 0.
  // This is to prevent issues when opening the keyboard in mobile devices.
  // The browser may set the scroll automatically to try to focus the input, but it
  // will mess up because the body is resized when the keyboard opens.
  useEffect(() => {
    if (!isMobileOrTablet || isReacting) {
      return;
    }

    document.documentElement.scrollTop = 0;
    scrollCaretIntoView();
  }, [isMobileOrTablet, isReacting, viewportSize.height]);

  // Edit image options

  const moreOptions = useMemo<BottomSheetOption[]>(() => {
    return [
      {
        type: 'item',
        label: 'Change photo',
        value: 'change-photo',
        icon: faRightLeft,
        onSelect: onUpdatePhoto,
      },
      {
        type: 'item',
        label: 'Remove photo',
        value: 'remove-photo',
        icon: faImage,
        destructive: true,
        onSelect: onRemovePhoto,
      },
    ];
  }, [onRemovePhoto, onUpdatePhoto]);

  return (
    <StyledStoryPlayer>
      {/* We should render the player portal as soon as possible.
      The component will not actually will not be mounted to the dom yet.*/}
      <InPortal node={playerPortalNode}>
        {type === 'video' ? (
          <StoryVideoPlayer key="video" fitMode={isFullscreen ? 'contain' : 'cover'} />
        ) : (
          <StoryAudioPlayer
            authorInitials={authorInitials}
            authorAvatarUrl={authorAvatarUrl}
            size={initialsSize}
            box={imageUrl !== null && !editing}
          />
        )}
      </InPortal>

      <Content>
        <ScrollArea ref={scrollRef} style={{ height: scrollAreaHeight }}>
          <TopOverlay
            onMouseOut={() => onMouseOver(false)}
            onMouseMove={() => onMouseOver(true)}
            data-showing={controlsVisible}
          >
            {isFullscreen && (
              <FullscreenStoryTitle
                type="serif"
                size={isMobileOrTablet ? 'm' : 'xxl'}
                color="inverse-on-surface-primary"
              >
                {title}
              </FullscreenStoryTitle>
            )}
            {isFullscreen && (
              <FullscreenCloseButton
                icon={faXmark}
                tooltip={{ label: 'Close', position: 'bottom' }}
                color="white"
                backgroundColor="transparent"
                onClick={onExitFullscreen}
              />
            )}
            {isMobileOrTablet == false && isFullscreen == false && (
              <>
                {recordedDate && (
                  <RMText type="sans" size="xxs" bold color="inverse-on-surface-primary">
                    Recorded {recordedDate}
                  </RMText>
                )}
              </>
            )}
            {onViewHighlightReel && isMobileOrTablet == false && isFullscreen == false && !editing && (
              <HighlightsReelButton onClick={onViewHighlightReel}>
                <FontAwesomeIcon size="xs" icon={faSparkles} color="var(--secondary)" />
                <RMText type="sans" size="xs" bold color="secondary">
                  View highlights
                </RMText>
              </HighlightsReelButton>
            )}
            {onViewHighlightReel && isMobileOrTablet && isFullscreen == false && !editing && (
              <MobileHighlightsReelButtonWrapper data-showing={controlsVisible}>
                <HighlightsReelButton onClick={onViewHighlightReel}>
                  <FontAwesomeIcon size="xs" icon={faSparkles} color="var(--secondary)" />
                  <RMText type="sans" size="xs" bold color="secondary">
                    View highlights
                  </RMText>
                </HighlightsReelButton>
              </MobileHighlightsReelButtonWrapper>
            )}
          </TopOverlay>

          <MediaWrapper onMouseOut={() => onMouseOver(false)} onMouseMove={() => onMouseOver(true)}>
            <BackgroundImage
              key={imageUrl}
              src={imageUrl ?? undefined}
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.1 }}
              exit={{ opacity: 0 }}
              transition={{ type: easeInOut(0.6) }}
              onClick={onToggleControlsVisibility}
            />

            {isMobile && controlsVisible && <StoryPlayerPlayButton />}

            {(imageUrl == null || primaryMedia == 'video') && (
              <MediaContainer data-type={type} onClick={onToggleControlsVisibility}>
                <OutPortalWrapper>
                  <OutPortal node={playerPortalNode} />
                </OutPortalWrapper>
              </MediaContainer>
            )}
            {imageUrl != null && primaryMedia == 'photo' && (
              <Image data-primary-media={true} src={imageUrl} onClick={onToggleControlsVisibility} />
            )}

            <AbsoluteMediaContainer
              onMouseOut={() => onMouseOver(false)}
              onMouseMove={() => onMouseOver(true)}
              transition={{ ease: 'easeInOut', duration: 0.3 }}
              animate={getMediaContainerAnimation(isMobile, controlsVisible)}
            >
              {(imageUrl != null || editing) && (
                <PipWrapper onClick={onToggleControlsVisibility}>
                  {type == 'video' && !editing && (
                    <MaximizeButton
                      tooltip={null}
                      icon={faUpRightAndDownLeftFromCenter}
                      backgroundColor="transparent"
                      color="white"
                      size={isMobileOrTablet ? 'sm' : 'lg'}
                      onClick={(e) => {
                        onChangePrimaryMedia(primaryMedia == 'photo' ? 'video' : 'photo');
                        // This will prevent the click from toggling the controls visibility
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  )}
                  {primaryMedia == 'photo' && !editing ? (
                    <OutPortalWrapper>
                      <OutPortal node={playerPortalNode} />
                    </OutPortalWrapper>
                  ) : (
                    <PipImageWrapper
                      data-empty={imageUrl == null}
                      disabled={!editing}
                      onClick={editing && imageUrl == null ? onUpdatePhoto : undefined}
                    >
                      {imageUrl != null ? (
                        <PipImageBottomSheetWrapper>
                          <RMBottomSheet
                            disabled={!editing}
                            options={moreOptions}
                            Trigger={
                              <>
                                <Image data-primary-media={false} src={imageUrl} onClick={onToggleControlsVisibility} />
                                {editing && (
                                  <PipImageEditButton>
                                    <FontAwesomeIcon icon={faImage} size="1x" />
                                    <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} bold>
                                      Edit photo
                                    </RMText>
                                  </PipImageEditButton>
                                )}
                              </>
                            }
                          />
                        </PipImageBottomSheetWrapper>
                      ) : (
                        <>
                          <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} bold>
                            Add photo
                          </RMText>
                          <FontAwesomeIcon icon={faCirclePlus} size="xl" />
                        </>
                      )}
                    </PipImageWrapper>
                  )}
                </PipWrapper>
              )}
              <PlayerScrubber promptText={promptText} hideThumb={isMobileOrTablet} />
            </AbsoluteMediaContainer>
          </MediaWrapper>

          {isMobileOrTablet ? (
            StorySummary
          ) : (
            <SummaryAndActionsDesktopWrapper>
              {StorySummary}
              {StoryActions}

              <AnimatePresence>
                {isRegenerating && <RegenerateDesktopWrapper>{StoryRegenerate}</RegenerateDesktopWrapper>}
              </AnimatePresence>
            </SummaryAndActionsDesktopWrapper>
          )}
        </ScrollArea>

        {isMobileOrTablet && <ActionsMobileWrapper ref={actionsWrapperRef}>{StoryActions}</ActionsMobileWrapper>}
        {isMobileOrTablet && <AnimatePresence>{isRegenerating && StoryRegenerate}</AnimatePresence>}
      </Content>
    </StyledStoryPlayer>
  );
}
