export function scrollCaretIntoView(): void {
  const selection = window.getSelection();
  if (selection == null || selection.rangeCount == 0) {
    return;
  }

  const range = selection.getRangeAt(0).cloneRange();

  // Create a temporary span element with a zero-width non-breaking space.
  const tempSpan = document.createElement('span');
  tempSpan.textContent = '\u200B';

  range.insertNode(tempSpan);

  // Scroll the temporary element into view
  tempSpan.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

  // After scrolling, move the caret after the span and remove the span.
  range.setStartAfter(tempSpan);
  range.collapse(true);

  // Clear the selection and apply updated range.
  selection.removeAllRanges();
  selection.addRange(range);

  // Remove the temporary span from the document.
  tempSpan.parentNode?.removeChild(tempSpan);
}
