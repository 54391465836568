import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { RMIconButton } from '../RMIconButton/RMIconButton.js';
import { RMText } from '../RMText/RMText.js';

export const TooltipBody = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-md);
  min-width: 284px;
  background-color: var(--color-spruce-10);
  border-radius: var(--radius-minimal);
  box-shadow: var(--elevation-bottom-3);

  body[data-mobile='true'] & {
    min-width: 230px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -6px;
    right: 16px;
    margin-left: -6px;
    border-width: 6px 6px 0 6px;
    border-style: solid;
    border-color: var(--color-spruce-10) transparent transparent transparent;
  }
`;

export const TooltipTextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const TooltipText = styled(RMText)`
  line-height: 1.5625rem;
`;

export const CloseButton = styled(RMIconButton)`
  &[data-extra-action='true'] {
    align-self: flex-start;
  }
`;
