import { createPortal } from 'react-dom';
import { JSONContent } from '@tiptap/react';

import SpinnerLottieAnimation from '@/assets/spinner.lottie.json';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { RMTextEditor } from '@/components/RMTextEditor/RMTextEditor';
import { useIsMobileOrTabletViewport } from '@/hooks/useIsMobileViewport';

import {
  Badge,
  Body,
  Footer,
  Header,
  LoadingContent,
  LottieSpinner,
  StyledStoryRegeneratePreview,
  summaryClass,
  titleClass,
} from './StoryRegeneratePreview.styles.js';

interface StoryRegeneratePreviewProps {
  title: JSONContent | null | undefined;
  previewSummary: JSONContent | null | undefined;
  canUpdate: boolean;
  onRegeneratePreviewCancel: () => void;
  onRegeneratePreviewConfirm: () => void;
}

export function StoryRegeneratePreview({
  title,
  previewSummary,
  canUpdate,
  onRegeneratePreviewCancel,
  onRegeneratePreviewConfirm,
}: StoryRegeneratePreviewProps) {
  const isMobileOrTablet = useIsMobileOrTabletViewport();

  const component = (
    <StyledStoryRegeneratePreview
      initial={{ translateY: '100%' }}
      animate={{ translateY: '0%', transition: { duration: 0.25, ease: 'easeOut' } }}
      exit={{ translateY: '100%', transition: { duration: 0.25, ease: 'easeIn' } }}
    >
      <Body>
        <Header>
          {isMobileOrTablet ? (
            <RMText style={{ letterSpacing: '1px' }} type="sans" size="xxs" bold color="on-surface-tertiary">
              STORY PREVIEW
            </RMText>
          ) : (
            <Badge type="sans" size="xxs" bold color="primary">
              STORY PREVIEW
            </Badge>
          )}
        </Header>
        <RMTextEditor value={title} editable={false} className={titleClass} />

        {canUpdate ? (
          <RMTextEditor value={previewSummary} editable={false} className={summaryClass} />
        ) : (
          <LoadingContent>
            <LottieSpinner loop animationData={SpinnerLottieAnimation} />
          </LoadingContent>
        )}
      </Body>

      <Footer>
        <RMButton onClick={onRegeneratePreviewCancel}>Cancel</RMButton>
        <RMButton background="primary" disabled={!canUpdate} onClick={onRegeneratePreviewConfirm}>
          Update story
        </RMButton>
      </Footer>
    </StyledStoryRegeneratePreview>
  );

  if (isMobileOrTablet) {
    const portalRoot = document.getElementById('dialog');
    if (!portalRoot) {
      throw new Error('missing-portal-dialog-root');
    }

    return createPortal(component, portalRoot);
  }

  return component;
}
