import { useCallback } from 'react';
import { EntityType } from '@remento/types/entity';
import { NotFoundError } from '@remento/types/error';
import { StoryDataType } from '@remento/types/story';

import { getRMTextEditorText, RMTextEditorStore } from '@/components/RMTextEditor/RMTextEditor.state';
import { resetForm } from '@/modules/form/form';
import { getInputValue } from '@/modules/form/input';
import { useServices } from '@/Services';
import { EntityMutation } from '@/services/api/cache';

import { StorySummaryForm } from '../forms/story-summary.form.js';

export function useStoryActions() {
  const { storyService, storyCacheService, entityCacheManagerService, storyViewerAnalyticsService } = useServices();

  const updateStory = useCallback(
    async (
      storyId: string,
      summaryForm: StorySummaryForm,
      titleTextEditor: RMTextEditorStore,
      summaryTextEditor: RMTextEditorStore,
    ) => {
      const story = await storyCacheService.getStory(storyId);
      if (story == null) {
        throw new NotFoundError('story-not-found', {
          origin: 'entity',
          entityType: EntityType.STORY,
          entityId: storyId,
        });
      }

      const mutations: EntityMutation[] = [];

      const type = getInputValue(summaryForm, 'type') ?? StoryDataType.HUMAN;
      const regenerateSettings = {
        perspective: getInputValue(summaryForm, 'perspective') ?? undefined,
        length: getInputValue(summaryForm, 'length') ?? undefined,
      };

      // Update title
      const title = getRMTextEditorText(titleTextEditor) ?? '';
      mutations.push(...storyService.createSetStoryTitleMutation(story, title, type, regenerateSettings));

      // Update summary
      const summary = getRMTextEditorText(summaryTextEditor) ?? '';
      mutations.push(...storyService.createSetStorySummaryMutation(story, summary, type, regenerateSettings));

      // Run mutations
      await entityCacheManagerService.mutate(mutations);

      // Analytics
      storyViewerAnalyticsService.onStorySummaryUpdated(type);

      resetForm(summaryForm);
    },
    [entityCacheManagerService, storyCacheService, storyService, storyViewerAnalyticsService],
  );

  return { updateStory };
}
